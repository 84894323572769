<template>
    <div class="flex flex-row">
        <Sidebar />
        <div v-if="!loading">
            <Navbar />
            <div class="main-page rollpage">
                <div class="flex flex-row items-center justify-between">
                    <div class="flex flex-row items-center">
                        <div class="box-return" @click="returnPage">
                            <Icon type="arrow-left" class="w-6 h-6" />
                        </div>
                        <div
                            class="mx-3 my-0 font-bold page-title title-proj cursor-text"
                        >
                            {{ store.formatText(project.general.title) }}
                        </div>
                    </div>
                    <div class="flex justify-end" v-if="!downloading">
                        <div
                            class="flex flex-row py-1 mx-6 text-sm btn-default cursor-pointer"
                            :title="$t('download_project')"
                            @click="downloadPtr"
                        >
                            <Icon type="file-download" class="w-6 h-6 mr-1" />
                            Download
                        </div>
                    </div>
                    <div v-else class="flex justify-end">
                        <div
                            class="flex flex-row py-1 text-sm btn-default cursor-pointer"
                            :title="$t('download_project')"
                        >
                            <Spinner size="w-6 h-6 mr-1 " />
                            {{ $t("loading") }}
                        </div>
                    </div>
                </div>

                <div class="over-about">
                    <div class="grid xl:grid-cols-4 md:grid-cols-2 gap-6">
                        <div class="w-full col-span-3 box-default">
                            <div class="flex flex-row justify-between">
                                <div class="flex flex-row">
                                    <div
                                        v-for="(btn, idbtn) of btnInfo"
                                        :key="idbtn"
                                    >
                                        <div
                                            class="py-1 text-sm btn-default"
                                            :class="
                                                btn.selected
                                                    ? 'btn-selected'
                                                    : ''
                                            "
                                            @click="selectInfo(btn)"
                                        >
                                            {{ $t(btn.name) }}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="py-1 text-sm btn-default"
                                    @click="addDocument"
                                    v-show="active(this.btnInfo[2].name)"
                                >
                                    {{ $t("add_document") }}
                                </div>
                            </div>

                            <div v-show="active(this.btnInfo[0].name)">
                                <General :info="project.general" />
                            </div>
                            <div v-show="active(this.btnInfo[1].name)">
                                <Project :info="project.projectInfo" />
                            </div>
                            <div v-show="active(this.btnInfo[2].name)">
                                <Documents :projectId="this.$route.params.id" />
                            </div>
                        </div>
                        <div class="w-full box-default">
                            <div class="mb-4 text-center page-title text-xl">
                                {{ $t("project_progress") }}
                            </div>
                            <div>
                                <ProjectProgress :prog="project.progress" />
                            </div>
                        </div>
                    </div>

                    <!-- ACTION BUTTONS -->
                    <div class="flex flex-row my-6">
                        <div
                            v-for="(button, index) in buttons"
                            :key="index"
                            :class="[
                                'w-48',
                                'btn-default',
                                {
                                    'btn-selected':
                                        showAction === button.action,
                                },
                            ]"
                            @click="actButton(button.action)"
                        >
                            {{ $t(button.label) }}
                        </div>
                    </div>
                    <div class="box-default" v-show="activate('activities')">
                        <Activities
                            :tbl="tblProject"
                            :projectId="this.$route.params.id"
                        />
                    </div>
                    <div class="box-default" v-show="activate('budget')">
                        <Budget :expenses="this.project" />
                    </div>
                    <div class="box-default" v-show="activate('status_report')">
                        <StatusReport :projectId="this.$route.params.id" />
                    </div>
                    <div class="box-default" v-show="activate('reports')">
                        <Reports :projectId="this.$route.params.id" />
                    </div>
                </div>
            </div>
        </div>
        <div
            class="w-full h-[100vh] flex items-center justify-center"
            v-if="loading"
        >
            <Spinner />
        </div>

        <ModalRequested />
        <ModalAddDoc :projectId="this.$route.params.id" />
        <ModalConsolidated />
        <ModalFinancial :projectId="this.$route.params.id" />
        <ModalAddStatus
            :projectId="this.$route.params.id"
            :act="this.tblProject"
        />
    </div>
</template>
<script>
import { store } from "@/store";
import Sidebar from "@/containers/Sidebar.vue";
import Navbar from "@/containers/Navbar.vue";
import Icon from "@/containers/UI/Icon.vue";

import ProjectProgress from "@/components/dashboards/ProjectProgress.vue";
import General from "@/components/pathProjects/projects/compProject/General.vue";
import Project from "@/components/pathProjects/projects/compProject/Project.vue";
import Documents from "@/components/pathProjects/projects/compProject/Documents.vue";

import Spinner from "@/containers/UI/Spinner.vue";
import axios from "axios";

import Budget from "./actionButtons/Budget.vue";
import StatusReport from "./actionButtons/StatusReport.vue";
import Activities from "./actionButtons/Activities.vue";
import FinancialPerformance from "./actionButtons/FinancialPerformance.vue";
import Reports from "./actionButtons/Reports.vue";

import ModalRequested from "@/components/pathProjects/projects/actionButtons/budgetBtn/modal/ModalRequested.vue";
import ModalConsolidated from "@/components/pathProjects/projects/actionButtons/budgetBtn/modal/ModalConsolidated.vue";
import ModalFinancial from "@/components/pathProjects/projects/actionButtons/budgetBtn/modal/ModalFinancial.vue";
import ModalAddStatus from "@/components/pathProjects/projects/actionButtons/budgetBtn/modal/ModalAddStatusReport.vue";
import ModalAddDoc from "../../modal/ModalAddDoc.vue";

export default {
    components: {
        Sidebar,
        Navbar,
        Icon,
        ProjectProgress,
        Activities,
        General,
        Project,
        Spinner,
        Budget,
        ModalRequested,
        ModalConsolidated,
        StatusReport,
        FinancialPerformance,
        ModalFinancial,
        ModalAddStatus,
        Documents,
        Reports,
        ModalAddDoc,
    },
    data() {
        return {
            store,
            loading: true,
            projDescription: {},
            project: {
                general: {
                    title: "",
                    processNumber: 9,
                    startDate: new Date(),
                    category: "",
                    description: "",
                    coordinator: "",
                    technicalManager: "",
                },
                projectInfo: {
                    anpCreditation: "",
                    keywords: [],
                    mainExecutor: "",
                    mainProponent: "",
                },
                progress: {
                    totalActivities: 0,
                    completedActivities: 0,
                    activitiesPercentage: 0,
                    budget: 0,
                    usedBudget: 0,
                },
                team: [],
            },
            btnInfo: [
                { name: "general", selected: true },
                { name: "project", selected: false },
                { name: "documents", selected: false },
            ],
            showComp: "general",

            tblProject: {},
            showAction: "activities",

            buttons: [
                { label: "activities", action: "activities" },
                { label: "budget", action: "budget" },
                { label: "Status Report", action: "status_report" },
            ],
            downloading: false,
        };
    },
    created() {
        store.position = "projects";
        this.getProgress();

        this.getProjectInfo();
    },
    methods: {
        async getProjectInfo() {
            const projectId = this.$route.params.id;

            const authHeader = store.getAuthHeader();

            const res = await axios.get(
                `${store.BACKEND_URL}/api/projects/${projectId}/`,
                { withCredentials: false, headers: authHeader }
            );
            if (res.status != 200) {
                this.$toast.error(
                    "Something went wrong retrieving project information."
                );
                return;
            }

            const stats = await this.getProjectsStats();
            const project = stats.filter(
                (project) => project._id === projectId
            );
            const completedActivities = project.length
                ? project[0].completed_activities
                : 0;

            this.loading = false;
            const data = res.data;
            const base = data.base;
            const technicalData = data.technical_data;
            const institutionalData = data.institutional_data;

            for (const category of store.expensesCategories) {
                this.project[category] = data[category];
            }

            this.project.general = {
                title: data.base.title,
                processNumber: Number(base.authorization_number),
                startDate: new Date(base.contract_date),
                category: technicalData.theme,
                description: technicalData.goal,
                coordinator: data.team.find(
                    (team_member) => team_member.role === "COORDENADOR"
                ),
                technicalManager: data.team.find(
                    (team_member) => team_member.role === "TÉCNICO"
                ),
            };

            let mainProponent = "No proponent";
            let biggestPercentage = 0;
            for (const proponent of institutionalData.proponents) {
                if (proponent.resource_percentage > biggestPercentage) {
                    mainProponent = proponent.company_name;
                    biggestPercentage = proponent.resource_percentage;
                }
            }

            this.project.projectInfo = {
                anpCreditation: base.anp_project_number,
                keywords: technicalData.keywords,
                mainProponent: mainProponent,
                mainExecutor: institutionalData.executor_companies.length
                    ? institutionalData.executor_companies[0].company_name
                    : "",
            };

            const usedBudget = project.length > 0 ? project[0].usedBudget : 0;
            const totalBudget = project.length > 0 ? project[0].totalBudget : 0;

            this.project.progress = {
                totalActivities: data.activities.length,
                completedActivities: completedActivities,
                activitiesPercentage:
                    project.length > 0 ? project[0].activitiesPercentage : 0,
                budget: totalBudget,
                usedBudget: usedBudget,
            };
            this.project.expenses = data.consumables_expenses;
            this.project.team = data.team;
        },
        async getProgress() {
            const projectId = this.$route.params.id;
            try {
                const authHeader = store.getAuthHeader();
                const projectResponse = await axios.get(
                    `${store.BACKEND_URL}/api/projects/${projectId}/`,
                    { withCredentials: false, headers: authHeader }
                );
                if (projectResponse.status != 200) {
                    this.$toast.error(
                        "Something went wrong retrieving project information."
                    );
                    return;
                }

                const res = await axios.get(
                    `${store.BACKEND_URL}/api/statistics/progress/`,
                    { withCredentials: false, headers: authHeader }
                );

                let tblProject = [];
                const project = res.data.find(
                    (proj) => proj._id === this.$route.params.id
                );
                const activities = {};

                if (project && project.activities_progress.length > 0) {
                    for (const act of project.activities_progress) {
                        if (act._id in activities) {
                            const prevAct = activities[act._id];
                            if (act.updated_at >= prevAct.updated_at) {
                                activities[act._id] = act;
                            }
                        } else {
                            activities[act._id] = act;
                        }
                    }

                    for (const act of projectResponse.data["activities"]) {
                        if (!(act._id in activities)) {
                            act["progress"] = 0;
                            act["updated_at"] = new Date();
                            activities[act._id] = act;
                        }
                    }

                    tblProject = Object.values(activities).map((act) => ({
                        id: act._id,
                        name: act.name,
                        description: act.description,
                        start_month: act.start_month,
                        final_month: act.end_month,
                        duration: act.end_month - act.start_month,
                        progress: act.progress,
                    }));
                }
                this.tblProject = tblProject;
            } catch (err) {
                console.log(err);
            }
        },
        async getProjectsStats() {
            const authHeader = store.getAuthHeader();
            const progressRes = await axios.get(
                `${store.BACKEND_URL}/api/statistics/progress/`,
                {
                    withCredentials: false,
                    headers: authHeader,
                }
            );
            if (progressRes.status != 200) {
                this.$toast.error(
                    "Something went wrong retrieving project information."
                );
                return;
            }
            const resourcesRes = await axios.get(
                `${store.BACKEND_URL}/api/statistics/resources/`,
                {
                    withCredentials: false,
                    headers: authHeader,
                }
            );
            if (resourcesRes.status != 200) {
                this.$toast.error(
                    "Something went wrong retrieving project information."
                );
                return;
            }
            const idToResource = {};
            for (const resource of resourcesRes.data) {
                idToResource[resource._id] = resource;
            }

            let joinedData = [];
            for (const projectProgress of progressRes.data) {
                const activities = {};

                for (const act of projectProgress.activities_progress) {
                    if (act._id in activities) {
                        const prevAct = activities[act._id];
                        if (act.updated_at >= prevAct.updated_at) {
                            activities[act._id] = act;
                        }
                    } else {
                        activities[act._id] = act;
                    }
                }

                const values = Object.values(activities);
                const activitiesPercentage =
                    values.reduce((acc, act) => acc + act.progress, 0) /
                    values.length;

                const projectResources = idToResource[projectProgress._id];
                joinedData.push({
                    ...projectProgress,
                    activitiesPercentage,
                    usedBudget: projectResources.used_expenses,
                    totalBudget: projectResources.total_expenses,
                });
            }
            return joinedData;
        },
        selectInfo(btn) {
            this.showComp = btn.name;
            for (const b of this.btnInfo) {
                if (b.name === btn.name) {
                    b.selected = true;
                } else {
                    b.selected = false;
                }
            }
        },
        checkOverflow() {
            const projDescription = this.$refs.projDescription;

            if (projDescription.scrollHeight > projDescription.clientHeight) {
                projDescription.classList.add("truncate");
            }
        },
        active: function (val) {
            return this.showComp === val;
        },
        returnPage() {
            this.$router.push("/projects");
        },
        activate(val) {
            return this.showAction === val;
        },
        actButton(val) {
            this.showAction = val;
        },
        async downloadPtr() {
            this.downloading = true;
            const projectId = this.$route.params.id;

            const authHeader = store.getAuthHeader();

            let res;
            try {
                res = await axios.post(
                    `${store.BACKEND_URL}/api/projects/${projectId}/download/`,
                    {},
                    {
                        responseType: "arraybuffer",
                        withCredentials: false,
                        headers: authHeader,
                    }
                );
            } catch (error) {
                this.$toast.error("Something went wrong downloading project.");
                console.error(error);
                this.downloading = false;
                return;
            }

            // Extrai o nome do arquivo do header Content-Disposition
            const contentDisposition = res.headers["content-disposition"];
            const fileNameMatch =
                contentDisposition &&
                contentDisposition.match(/filename="(.+?)"/);

            const fileName = fileNameMatch
                ? fileNameMatch[1]
                : `ptr-${projectId}.xlsx`;

            const blob = new Blob([res.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            this.downloading = false;
        },
        addDocument() {
            this.emitter.emit("open modal document", true);
        },
    },
};
</script>
