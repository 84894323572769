<template>
    <div class="max-h-[50vh] overflow-y-auto relative">
        <div class="overLines">
            <div
                v-for="(line, index) in filteredLines"
                :key="line"
                :class="[
                    'line-container',
                    {
                        'items-start': index === 0,
                        'items-end': index === filteredLines.length - 1,
                    },
                ]"
            >
                <span class="line-number text-gray-400">{{ line }}</span>
                <div class="line"></div>
            </div>
        </div>
        <div class="box-dash-deadline">
            <div
                class="flex flex-col items-center mb-4 w-full"
                v-for="(pr, idPr) in progress"
                :key="idPr"
            >
                <div
                    class="w-full m-2 rounded-r-lg text-sm h-10 relative bg-gray-100"
                >
                    <div
                        class="h-10 rounded-r-lg flex items-center justify-end font-bold px-4 text-white"
                        :title="pr.actual"
                        :style="{
                            width: parseFloat(pr.size) <= 5 ? '8%' : pr.size,
                            backgroundColor: pr.color,
                        }"
                    ></div>
                    <div
                        class="absolute inset-0 flex items-center justify-end px-4 font-bold text-gray-600"
                        :title="pr.actual"
                        v-if="parseFloat(pr.size) != 100"
                    ></div>
                </div>
            </div>
        </div>
        <div class="pt-4 flex flex-row">
            <div
                class="flex flex-row items-center justify-center"
                v-for="(pr, idPr) in progress"
            >
                <span
                    class="w-6 h-4 mx-1 rounded-md"
                    :style="{ backgroundColor: pr.color }"
                ></span>
                <div class="text-sm font-medium mr-6" :title="pr.name">
                    {{ store.formatText(pr.name) }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from "@/store";
import axios from "axios";

export default {
    data() {
        return {
            loaded: false,
            store,
            selectedProjects: [],
            projectsInfo: [],
            biggestDeadline: 0,
        };
    },

    beforeCreate() {
        this.emitter.on("updateGraph", (value) => {
            if (this.selectedProjects.includes(value)) {
                const idx = this.selectedProjects.indexOf(value);
                this.selectedProjects.splice(idx, 1);
            } else {
                this.selectedProjects.push(value);
            }
        });
    },

    async beforeMount() {
        await this.getProjectsDeadlines();
        this.loaded = true;
    },

    computed: {
        filteredLines() {
            const lines = [];
            const step = 3;
            const totalLines = this.biggestDeadline;

            for (let i = 1; i <= totalLines; i++) {
                if (i === 1 || i === totalLines || i % step === 0) {
                    lines.push(i);
                }
            }

            return lines;
        },

        progress() {
            const filteredProjects = this.projectsInfo.filter((project) =>
                this.selectedProjects.includes(project._id)
            );

            const backgroundColor = [
                "#6CD6AA",
                "#ED7545",
                "#06B6D4",
                "#B778A2",
                "#155E75",
            ];
            const subBackground = [
                "#d7ffef",
                "#ffd8c8",
                "#d8f9ff",
                "#ffd3f0",
                "#d6f5ff",
            ];

            let data = [];
            for (const project of filteredProjects) {
                const startDate = new Date(project.start_date);
                const endDate = new Date(project.end_date);

                const colorIndex = data.length % backgroundColor.length;
                const remainingMonths = store.getMonthsDiff(startDate, endDate);

                const today = new Date();
                let currentSize = store.getMonthsDiff(today, startDate);
                currentSize = Math.max(currentSize, 0);

                const actualMonths = store.getMonthsDiff(today, startDate);
                const actualLabel = `${actualMonths} ${this.$t(
                    actualMonths === 1 ? "month" : "months"
                )}`;

                data.push({
                    name: project.title,
                    size: currentSize,
                    color: backgroundColor[colorIndex],
                    subColor: subBackground[colorIndex],
                    actual: actualLabel,
                    final: `${store.getMonthsDiff(today, endDate)} ${this.$t(
                        "months"
                    )} `,
                    value: `${remainingMonths} ${this.$t("months")}`,
                });
            }

            return data.map((v) => {
                const sizePercentage = (v.size / this.biggestDeadline) * 100;
                const sizeWithDecimals = Math.min(sizePercentage, 100).toFixed(
                    2
                );
                const sizeWithoutDecimals = Math.min(
                    sizePercentage,
                    100
                ).toFixed(0);

                v.size = sizeWithDecimals.endsWith(".00")
                    ? `${sizeWithoutDecimals}%`
                    : `${sizeWithDecimals}%`;

                return v;
            });
        },
    },

    methods: {
        calculateBiggestDeadline() {
            let maxDeadline = 0;
            for (const project of this.projectsInfo) {
                const startDate = new Date(project.start_date);
                const endDate = new Date(project.end_date);
                const remainingMonths = store.getMonthsDiff(startDate, endDate);
                maxDeadline = Math.max(maxDeadline, remainingMonths);
            }
            this.biggestDeadline = maxDeadline;
        },

        async getProjectsDeadlines() {
            const authHeader = store.getAuthHeader();
            if (this.projectsInfo.length != 0) {
                return;
            }

            const res = await axios.get(
                `${store.BACKEND_URL}/api/statistics/projects-deadlines/`,
                {
                    withCredentials: false,
                    headers: authHeader,
                }
            );

            if (res.status != 200) {
                this.$toast.error(
                    "Something went wrong retrieving projects deadlines."
                );
                return;
            }

            this.projectsInfo = res.data;
            this.selectedProjects = this.projectsInfo.map((v) => v._id);
            this.calculateBiggestDeadline(); // Adicionado
        },
    },

    async beforeMount() {
        await this.getProjectsDeadlines();
        this.loaded = true;
    },
};
</script>
<style>
.overLines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
}

.line {
    border-left: 1px solid rgb(206, 206, 206);
    height: 80%;
    visibility: visible;
}

.box-dash-deadline {
    min-height: 17rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.line-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
