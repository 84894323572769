<template>
    <div>
        <div
            class="bg-modal"
            :style="{
                width: openModal ? '100%' : '0px',
                opacity: openModal ? '1' : '0',
            }"
        ></div>
        <div class="card-bg" v-if="openModal">
            <div class="card-modal fadeIn">
                <div class="flex justify-between">
                    <div class="m-1 text-xl">{{ $t("add_document") }}</div>
                    <div @click="closeModal">
                        <Icon type="x-circle" class="w-6 h-6 cursor-pointer" />
                    </div>
                </div>
                <div class="my-1 separete"></div>
                <div class="px-4 my-3">
                    <div class="mt-4 mb-8">
                        <input type="file" id="document-id" ref="documentRef" />

                        <div class="flex flex-col my-4">
                            <label for="title" class="label-ptr"
                                >{{ $t("reference") }}
                                <sup>(100 {{ $t("characters") }})</sup></label
                            >
                            <textarea
                                id="description"
                                rows="3"
                                class="sty-input"
                                maxlength="100"
                                v-model="description"
                                @input="updateCharacterCount"
                            ></textarea>
                            <small
                                >{{ remainingCharacters }}
                                {{ $t("characters_remaining") }}</small
                            >
                        </div>
                    </div>
                    <div class="flex justify-end">
                        <div
                            class="btn-default btn-clear mx-0"
                            @click="saveDocument"
                        >
                            {{ $t("add_registry") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Icon from "@/containers/UI/Icon.vue";
import { store } from "@/store";
import axios from "axios";
export default {
    components: {
        Icon,
    },
    props: {
        projectId: String,
    },
    data() {
        return {
            openModal: false,
            description: "",
            maxLength: 100,
            file: null,
            store,
        };
    },
    computed: {
        remainingCharacters() {
            return this.maxLength - this.description.length;
        },
    },
    created() {
        this.emitter.on("open modal document", () => {
            this.openModal = true;
        });
    },
    methods: {
        closeModal() {
            this.openModal = false;
        },
        async saveDocument() {
            this.uploading = true;
            const authHeader = store.getAuthHeader();

            const fileInput = this.$refs.documentRef;
            if (
                !fileInput ||
                !fileInput.files ||
                fileInput.files.length === 0
            ) {
                this.$toast.warning("Please select a file to upload.");
                this.uploading = false;
                return;
            }

            if (fileInput.files.length > 1) {
                this.$toast.warning("Only one file can be uploaded at a time.");
                this.uploading = false;
                return;
            }

            const file = fileInput.files[0];
            const formData = new FormData();

            const addDoc = {
                formData,
                reference: this.description,
            };

            formData.append("document", file);

            try {
                const res = await axios.post(
                    `${store.BACKEND_URL}/api/projects/${this.projectId}/documents/`,
                    formData,
                    { withCredentials: false, headers: authHeader }
                );

                if (res.status === 201) {
                    this.emitter.emit("reload documents", true);
                    fileInput.value = "";
                    this.description = "";
                }

                if (res.status >= 400) {
                    this.$toast.error("Something went wrong uploading file.");
                }
            } catch (error) {
                this.$toast.error("An error occurred during file upload.");
            } finally {
                this.uploading = false;
            }
        },
    },
};
</script>
