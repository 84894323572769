<template>
    <div>
        <div class="flex flex-row justify-between py-3 my-3">
            <div>
                <div class="flex flex-col mb-3">
                    <div class="title-form">{{ $t("project_title") }}</div>
                    <div class="value-form">{{ info.title }}</div>
                </div>
                <div class="grid grid-cols-2">
                    <div class="flex flex-col">
                        <div class="title-form">{{ $t("process_number") }}</div>
                        <div class="value-form">{{ info.processNumber }}</div>
                    </div>
                    <div class="flex flex-col">
                        <div class="title-form">{{ $t("start_date") }}</div>
                        <div class="value-form">
                            {{ store.formatDate(info.startDate) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="my-2 separete"></div>
        <div class="flex flex-col items-start justify-start mt-4">
            <div class="mb-2 title-form">{{ $t("description") }}</div>
            <div class="value-form">
                {{ info.description }}
            </div>
        </div>
        <div class="mt-8 mb-2">
            <div class="grid grid-cols-2">
                <div class="flex flex-row justify-between">
                    <div class="flex flex-col">
                        <div class="title-form">{{ $t("coordinator") }}</div>
                        <div class="value-form">
                            {{ info.coordinator ? info.coordinator.name : "" }}
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <div class="title-form">
                            {{ $t("technical_manager") }}
                        </div>
                        <div class="value-form">
                            {{
                                info.technicalManager
                                    ? info.technicalManager.name
                                    : ""
                            }}
                        </div>
                    </div>
                </div>

                <div class="flex flex-col items-end">
                    <div class="title-form">{{ $t("submission_date") }}</div>
                    <div class="value-form">
                        {{ store.formatDate(info.startDate) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Icon from "@/containers/UI/Icon.vue";
import { store } from "@/store";
import axios from "axios";

import Spinner from "@/containers/UI/Spinner.vue";

export default {
    components: {
        Icon,
        Spinner,
    },
    props: {
        info: Object,
    },
    data() {
        return {
            store,
        };
    },
};
</script>
