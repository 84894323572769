<template>
    <div class="txt-titulo">{{ $t("achievement_obligation") }}</div>
    <Bar
        id="my-chart-id"
        width="1005"
        height="250"
        :options="chartOptions"
        :data="chartData"
    />
</template>

<script>
import { store } from "@/store";
import axios from "axios";

import { Bar } from "vue-chartjs";
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default {
    components: { Bar },
    data() {
        return {
            loaded: false,
            store,
            selectedProjects: [],
            projectsInfo: [],
            chartOptions: {
                responsive: true,
                borderRadius: 7,
                maxBarThickness: 70,
                categoryPercentage: 0.2,
                scales: {
                    x: {
                        ticks: {
                            font: {
                                size: 10.8,
                            },
                        },
                    },
                },
                plugins: {
                    legend: {
                        position: "bottom",
                        labels: {
                            font: {
                                size: 16,
                            },
                            usePointStyle: true,
                            pointStyle: "circle",
                            boxWidth: 8,
                            boxHeight: 8,
                        },
                    },
                    filler: {
                        propagate: true,
                    },
                    datalabels: {
                        display: false,
                    },
                    tooltip: {
                        enabled: true,
                        callbacks: {
                            label: (context) => {
                                let label = context.dataset.label || "";
                                if (label) {
                                    label += ": ";
                                }
                                if (context.parsed.y !== null) {
                                    label += new Intl.NumberFormat(
                                        this.$t("language"),
                                        {
                                            style: "currency",
                                            currency: this.$t("format_money"),
                                        }
                                    ).format(context.parsed.y);
                                }
                                return label;
                            },
                        },
                    },
                },
            },
        };
    },

    beforeCreate() {
        this.emitter.on("updateGraph", (value) => {
            if (this.selectedProjects.includes(value)) {
                const idx = this.selectedProjects.indexOf(value);
                this.selectedProjects.splice(idx, 1);
            } else {
                this.selectedProjects.push(value);
            }
        });
    },

    async beforeMount() {
        const authHeader = store.getAuthHeader();
        await this.getProjectsOverview(authHeader);
        this.loaded = true;
    },

    computed: {
        chartData() {
            const filteredProjects = this.projectsInfo;

            const categories = {
                // glosa: { name: this.$t("glosa"), color: "#06B6D4" },
                // unused_resources: {
                //     name: this.$t("unused_resources"),
                //     color: "#6CD6AA",
                // },
                obligation: { name: this.$t("obligation"), color: "#155E75" },
                contracts: { name: this.$t("contracts"), color: "#ED7545" },
                realization: { name: this.$t("realization"), color: "#B778A2" },
            };

            const years = filteredProjects.map((x) => parseInt(x.year)).sort();

            const datasets = [];
            for (const [key, info] of Object.entries(categories)) {
                const categoryData = [].fill(null, years.length);

                for (const project of filteredProjects) {
                    const categoryidx = years.indexOf(project.year);
                    if (categoryidx == -1) {
                        continue;
                    }

                    if (categoryData[categoryidx] == null) {
                        categoryData[categoryidx] = 0;
                    }
                    categoryData[categoryidx] += project[key];
                }

                datasets.push({
                    label: info.name,
                    backgroundColor: info.color,
                    data: categoryData,
                });
            }

            return {
                labels: years.map((y) => `${y}/${y + 1}`),
                datasets,
            };
        },
    },

    methods: {
        async getProjectsOverview(authHeader) {
            if (this.projectsInfo.length != 0) {
                return;
            }

            let res;
            try {
                res = await axios.get(
                    `${store.BACKEND_URL}/api/statistics/projects-overview/`,
                    {
                        withCredentials: false,
                        headers: authHeader,
                    }
                );
            } catch (er) {
                console.log(er);
                return;
            }
            if (res.status != 200) {
                this.$toast.error(
                    "Something went wrong retrieving projects overview information."
                );
                return;
            }

            this.projectsInfo = res.data;
            this.selectedProjects = this.projectsInfo.map((v) => v._id);
        },
    },
};
</script>

<style>
.txt-titulo {
    margin-bottom: 1rem;
    font-weight: 600;
}
</style>
