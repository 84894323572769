<template>
    <div>
        <div v-show="active('CurrentBudget')">
            <CurrentBudget :expenses="this.expenses" />
        </div>
    </div>
</template>
<script>
import CurrentBudget from "./budgetBtn/CurrentBudget.vue";
import BudgetConsolidated from "./budgetBtn/BudgetConsolidated.vue";
export default {
    props: {
        expenses: Array,
    },
    components: {
        CurrentBudget,
        BudgetConsolidated,
    },
    data() {
        return {
            showComponent: "CurrentBudget",
        };
    },
    methods: {
        active: function (val) {
            return this.showComponent === val;
        },
        makeActive: function (val) {
            this.showComponent = val;
        },
    },
};
</script>
