import axios from "axios";
import { reactive } from "vue";

const lightThemeColors = {
    iconTheme: "sun",

    bgPage: "#FFFCFE",
    mainText: "#1C1B1F",
    btnDefault: "#E1DFE2",

    txtDescription: "#495465",
    txtBtnDefault: "#605D62",

    borderInput: "#CBC9CC",

    hoverTd: "#c0d0d8",
    hoverBtnDefault: "#B6B4B7",
};

const expensesCategories = [
    "consumables_expenses",
    "tickets_expenses",
    "rates_and_costs_expenses",
    "service_expenses",
    "tib_service_expenses",
    "prototype_expenses",
    "construction_and_installation_expenses",
    "equipment_expenses",
    "rights_and_properties_expenses",
    "costs_expenses",
    "supply_capacity_expenses",
    "scholarship_expenses",
    "allowance_expenses",
    "specific_expenses",
    "tests_expenses",
    "importation_expenses",
    "operational_expenses",
    "refund_expenses",
    "taxes_expenses",
    "other_sources",
];

export const store = reactive({
    position: "Dashboard",
    usuarios: [],
    profile: [],
    expensesCategories,
    ptrDatas: [],
    ptr: {},

    BACKEND_URL: "https://ideation.digital:8000",
    getMonthsDiff(date1, date2) {
        // Ensure date1 is the earlier date
        if (date1 > date2) {
            [date1, date2] = [date2, date1];
        }

        // Calculate the difference in years and months
        let yearDiff = date2.getFullYear() - date1.getFullYear();
        let monthDiff = date2.getMonth() - date1.getMonth();

        // Total difference in months
        let totalMonthsDiff = yearDiff * 12 + monthDiff;

        return totalMonthsDiff;
    },
    toTitleCase(str) {
        if (!str) {
            return "";
        }
        return str.toLowerCase().replace(/\s\w/g, (s) => s.toUpperCase());
    },
    remainingCharacters(txt, max) {
        return max - txt.length;
    },

    getAuthHeader() {
        const accessToken = localStorage.getItem("access-token");
        return {
            Authorization: `Bearer ${accessToken}`,
            "X-Institution-ID": "65ba7ae9884435be51ebf5ef",
        };
    },

    async getPtrDatas() {
        try {
            const response = await axios.get(
                `${store.BACKEND_URL}/api/projects/partial/`,
                { withCredentials: false, headers: this.getAuthHeader() }
            );
            this.ptrDatas = response.data;
        } catch (err) {
            console.log(err);
        }
    },

    generateRandomId() {
        const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        const digits = "0123456789";
        let result = "";

        for (let i = 0; i < 4; i++) {
            result += letters.charAt(
                Math.floor(Math.random() * letters.length)
            );
        }

        for (let i = 0; i < 4; i++) {
            result += digits.charAt(Math.floor(Math.random() * digits.length));
        }

        return result;
    },
    calculateProjectTotalExpenses(project) {
        const totalEmployeesExpenses = project.team.reduce((acc, e) => {
            return acc + e.total_payment + e.total_benefits;
        }, 0);

        let totalValue = totalEmployeesExpenses;
        for (const category of expensesCategories) {
            for (const expense of project[category]) {
                totalValue += expense.value;
            }
        }
        return totalValue;
    },

    formatDate(data) {
        const dataObj = new Date(data);
        const dia = String(dataObj.getUTCDate()).padStart(2, "0");
        const mes = String(dataObj.getUTCMonth() + 1).padStart(2, "0");
        const ano = dataObj.getUTCFullYear();
        return `${dia}/${mes}/${ano}`;
    },
    dateformat(data) {
        const dataObj = new Date(data);
        const dia = String(dataObj.getDate()).padStart(2, "0");
        const mes = String(dataObj.getMonth() + 1).padStart(2, "0");
        const ano = dataObj.getFullYear();
        return `${ano}/${mes}/${dia}`;
    },
    formatMoney(value) {
        return value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
        });
    },
    formatText(text) {
        return (
            text.toString().charAt(0).toUpperCase() +
            text.toString().slice(1).toLowerCase()
        );
    },
    formatCurrency(value) {
        if (!value) return "";
        value = value.replace(/\D/g, "");
        let numberValue = parseInt(value) / 100;
        return numberValue.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
        });
    },
    formatValueToSave(value) {
        return parseFloat(
            value.replace("R$", "").replace(".", "").replace(",", ".").trim()
        );
    },
    parseCurrency(value) {
        if (!value) return 0;
        value = value.replace(/\D/g, "");
        return parseInt(value);
    },
    formatCNPJ(cnpj) {
        return cnpj.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
            "$1.$2.$3/$4-$5"
        );
    },
    formatCPF(cpf) {
        return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
    },
    getMonthName(monthNumber) {
        const months = [
            "january",
            "february",
            "march",
            "april",
            "may",
            "june",
            "july",
            "august",
            "september",
            "october",
            "november",
            "december",
        ];
        return monthNumber == "-" ? "-" : months[monthNumber - 1];
    },
    colors: {
        ...lightThemeColors,
    },
});
