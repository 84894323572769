<template>
    <div>
        <div class="max-h-[35vh] flex justify-center relative overflow-y-auto">
            <div class="vertical-lines z-0">
                <div
                    v-for="(line, index) in horizontalLines.slice().reverse()"
                    :key="index"
                    class="v-line flex justify-between"
                    :class="{
                        'items-end': index === horizontalLines.length - 1,
                        'items-start': index === 0,
                        'items-center':
                            index !== 0 && index !== horizontalLines.length - 1,
                    }"
                >
                    <div class="border-t w-full"></div>
                    <span class="line-number text-gray-400">{{
                        abbreviateNumber(line.value)
                    }}</span>
                </div>
            </div>
            <div
                class="flex flex-col flex-nowrap z-10 justify-end w-12 h-80 mx-4 bg-gray-100 rounded-t-lg overflow-hidden"
                role="progressbar"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
                v-for="(pr, idPr) in progress"
                :key="idPr"
            >
                <div
                    class="rounded-t-lg overflow-hidden"
                    :title="pr.tooltip"
                    :style="{
                        height: pr.size,
                        backgroundColor:
                            pr.size == '100%' ? '#E1DFE2' : pr.color,
                    }"
                ></div>
            </div>
        </div>
        <div class="pt-4 flex flex-row">
            <div
                class="flex flex-row items-center justify-center"
                v-for="(pr, idPr) in progress"
            >
                <span
                    class="w-6 h-4 mx-1 rounded-md"
                    :style="{ backgroundColor: pr.color }"
                ></span>
                <div class="text-sm font-medium mr-6" :title="pr.name">
                    {{ store.formatText(pr.name) }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from "@/store";
import axios from "axios";

export default {
    data() {
        return {
            loaded: false,
            store,
            selectedProjects: [],
            projectsInfo: [],
            horizontalLines: [],
        };
    },

    beforeCreate() {
        this.emitter.on("updateGraph", (value) => {
            if (this.selectedProjects.includes(value)) {
                const idx = this.selectedProjects.indexOf(value);
                this.selectedProjects.splice(idx, 1);
            } else {
                this.selectedProjects.push(value);
            }
        });
    },

    async beforeMount() {
        await this.getProjectsDeadlines();
        this.loaded = true;
    },

    computed: {
        progress() {
            const filteredProjects = this.projectsInfo.filter((project) =>
                this.selectedProjects.includes(project._id)
            );

            let data = [];
            const backgroundColor = [
                "#6CD6AA",
                "#ED7545",
                "#06B6D4",
                "#B778A2",
                "#155E75",
            ];
            const subBackground = [
                "#d7ffef",
                "#ffd8c8",
                "#d8f9ff",
                "#ffd3f0",
                "#d6f5ff",
            ];
            for (let i = 0; i < filteredProjects.length; i++) {
                const project = filteredProjects[i];
                const unused = project.total_expenses - project.used_expenses;
                const unusedPercentage =
                    100 - Math.round((unused / project.total_expenses) * 100);

                const used = store.formatMoney(project.used_expenses);
                const total = store.formatMoney(project.total_expenses);
                const usedPerTotal = `${used} / ${total}`;

                const colorIndex = i % backgroundColor.length;

                data.push({
                    name: project.title,
                    size: `${unusedPercentage}%`,
                    color: backgroundColor[colorIndex],
                    subColor: subBackground[colorIndex],
                    tooltip: usedPerTotal,
                    value: store.formatMoney(unused),
                });
            }

            return data;
        },
    },

    methods: {
        async getProjectsDeadlines() {
            const authHeader = store.getAuthHeader();
            if (this.projectsInfo.length != 0) {
                return;
            }
            let res;
            try {
                res = await axios.get(
                    `${store.BACKEND_URL}/api/statistics/resources/`,
                    {
                        withCredentials: false,
                        headers: authHeader,
                    }
                );
            } catch (er) {
                console.log(er);
                return;
            }

            if (res.status != 200) {
                this.$toast.error(
                    "Something went wrong retrieving projects used resources."
                );
                return;
            }

            this.projectsInfo = res.data;
            this.selectedProjects = this.projectsInfo.map((v) => v._id);
        },
        calculateHorizontalLines() {
            const maxExpense = Math.max(
                ...this.projectsInfo.map((p) => p.total_expenses)
            );
            const linesCount = 10;
            const increment = maxExpense / (linesCount - 1);

            let lines = [];
            for (let i = 0; i < linesCount; i++) {
                const value = increment * i;
                lines.push({
                    value: value,
                    position: 100 - (i / (linesCount - 1)) * 100,
                });
            }

            this.horizontalLines = lines;
        },
        abbreviateNumber(value) {
            if (value >= 1e6) {
                return (value / 1e6).toFixed(1) + "M";
            } else if (value >= 1e3) {
                return (value / 1e3).toFixed(1) + "K";
            } else {
                return value.toFixed(2);
            }
        },
    },
    async beforeMount() {
        await this.getProjectsDeadlines();
        this.calculateHorizontalLines();
        this.loaded = true;
    },
};
</script>
<style>
.vertical-lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
    flex-direction: column;
}
</style>
