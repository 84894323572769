<template>
    <div>
        <div class="mb-8">
            <div class="flex flex-row justify-between">
                <div class="my-2 font-bold">{{ $t("current_budget") }}</div>
            </div>
        </div>
        <div class="mb-4">
            <div
                class="p-2 font-bold rounded-t-lg bg-thead-tbl border-separete"
            >
                {{ $t("capital_expenditure") }}
            </div>
            <table class="min-w-full">
                <thead class="border border-gray-300 bg-thead">
                    <tr>
                        <th
                            class="px-4 py-3 text-xs font-normal uppercase text-start"
                        >
                            {{ $t("expense_category") }}
                        </th>
                        <th
                            class="py-3 text-xs font-normal uppercase text-start"
                        >
                            {{ $t("value") }} (R$)
                        </th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody class="border border-gray-300">
                    <template
                        v-for="(ds, dsID) in expensesRequested"
                        :key="dsID"
                    >
                        <tr :class="ds.isActive ? 'bg-thead-tbl' : ''">
                            <td colspan="1" class="px-3 w-[60%]">
                                {{ $t(ds.name) }}
                            </td>
                            <td>
                                {{ store.formatMoney(ds.value) }}
                            </td>
                            <td></td>
                            <td class="w-24 px-6">
                                <div
                                    class="flex items-center justify-center h-8 bg-white border border-gray-400 rounded-md cursor-pointer"
                                    @click="getRowExpenses(ds)"
                                >
                                    <Icon
                                        class="w-5 h-5 color-icon"
                                        type="arrow-down"
                                        :class="
                                            ds.isActive
                                                ? 'rotate-up'
                                                : 'rotate-down'
                                        "
                                    />
                                </div>
                            </td>
                        </tr>
                        <template
                            v-if="ds.isActive"
                            :colspan="expensesRequested.length"
                        >
                            <tr class="italic bg-gray-100">
                                <td class="px-10 border-none">Item</td>
                                <td class="border-none w-52">
                                    {{ $t("value") }}
                                </td>
                                <td class="border-none"></td>
                                <td class="border-none"></td>
                            </tr>
                            <tr
                                v-for="(qt, index) in ds.quantities"
                                :key="index"
                                class="bg-gray-100 border-b-2 border-gray-400"
                            >
                                <td
                                    :class="{
                                        'last-item':
                                            index === ds.quantities.length - 1,
                                    }"
                                    class="px-3 border-none"
                                >
                                    <div v-if="!qt.edit">
                                        {{ index + 1 + " - " + qt.name }}
                                    </div>
                                    <input
                                        v-else
                                        v-model="qt.name"
                                        class="w-full p-2 rounded-md border border-gray-400 outline-none"
                                    />
                                </td>
                                <td
                                    :class="{
                                        'last-item':
                                            index === ds.quantities.length - 1,
                                    }"
                                    class="border-none"
                                >
                                    <div v-if="!qt.edit" class="w-52">
                                        {{ store.formatMoney(qt.value) }}
                                    </div>
                                    <input
                                        type="text"
                                        v-else
                                        class="w-52 p-2 rounded-md border border-gray-400 outline-none"
                                        v-model="qt.value"
                                        @input="onInput(index, $event)"
                                    />
                                </td>
                                <td
                                    :class="{
                                        'last-item':
                                            index === ds.quantities.length - 1,
                                    }"
                                ></td>
                                <td
                                    :class="{
                                        'last-item':
                                            index === ds.quantities.length - 1,
                                    }"
                                >
                                    <div class="flex justify-start">
                                        <div
                                            class="cursor-pointer"
                                            @click="editLine(qt, ds)"
                                        >
                                            <Icon
                                                :type="
                                                    qt.edit ? 'check' : 'pencil'
                                                "
                                                class="w-5 h-5"
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </template>
                    <tr class="footer-total">
                        <td class="px-3 py-4 text-sm uppercase">
                            {{ $t("total_value") }}
                        </td>
                        <td>
                            <strong>{{ totalValue }}</strong>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="mb-4">
            <div
                class="p-2 font-bold rounded-t-lg bg-thead-tbl border-separete"
            >
                {{ $t("current_expenses") }}
            </div>
            <table class="w-full">
                <thead class="border border-gray-300 bg-thead">
                    <tr>
                        <th
                            class="p-3 text-xs font-normal uppercase text-start"
                        >
                            {{ $t("expense_category") }}
                        </th>
                        <th
                            class="text-xs font-normal uppercase size-qtd text-start"
                        >
                            {{ $t("quantity_items") }}
                        </th>
                        <th class="text-xs font-normal uppercase text-start">
                            {{ $t("expense_value") }} (R$)
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody class="border border-gray-300">
                    <template v-for="(ds, dsId) in currentExpenses" :key="dsId">
                        <tr :class="ds.isActive ? 'bg-thead-tbl' : ''">
                            <td class="px-3">{{ $t(ds.name) }}</td>
                            <td>{{ ds.amount }}</td>
                            <td>{{ store.formatMoney(ds.value) }}</td>
                            <td></td>
                            <td></td>
                            <td class="w-24 px-6">
                                <div
                                    class="flex items-center justify-center h-8 bg-white border border-gray-400 rounded-md cursor-pointer"
                                    @click="getRow(ds)"
                                >
                                    <Icon
                                        class="w-5 h-5 color-icon"
                                        type="arrow-down"
                                        :class="
                                            ds.isActive
                                                ? 'rotate-up'
                                                : 'rotate-down'
                                        "
                                    />
                                </div>
                            </td>
                        </tr>
                        <template
                            v-if="ds.isActive"
                            :colspan="currentExpenses.length"
                        >
                            <tr
                                class="italic bg-gray-100 border-b-2 border-gray-400"
                            >
                                <td class="px-10 border-none">Item</td>
                                <td class="border-none"></td>
                                <td class="border-none w-52">
                                    {{ $t("value") }}
                                </td>
                                <td class="border-none"></td>
                                <td class="border-none"></td>
                                <td class="border-none"></td>
                            </tr>
                            <tr
                                v-for="(qt, index) in ds.quantities"
                                :key="index"
                                class="bg-gray-100 border-b-2 border-gray-200"
                            >
                                <td
                                    :class="{
                                        'last-item':
                                            index === ds.quantities.length - 1,
                                    }"
                                    class="px-3 border-none"
                                >
                                    <span v-if="!qt.edit"
                                        >{{ index + 1 }} - {{ qt.name }}</span
                                    >
                                    <input
                                        v-else
                                        v-model="qt.name"
                                        class="w-full p-2 rounded-md border border-gray-400 outline-none"
                                    />
                                </td>
                                <td
                                    :class="{
                                        'last-item':
                                            index === ds.quantities.length - 1,
                                    }"
                                    class="border-none"
                                ></td>
                                <td
                                    :class="{
                                        'last-item':
                                            index === ds.quantities.length - 1,
                                    }"
                                    class="border-none"
                                >
                                    <span v-if="!qt.edit">{{
                                        store.formatMoney(qt.value)
                                    }}</span>
                                    <input
                                        v-else
                                        v-model="qt.value"
                                        @input="onInputCurrent(index, $event)"
                                        class="w-32 p-2 rounded-md border border-gray-400 outline-none"
                                    />
                                </td>
                                <td></td>
                                <td></td>
                                <td
                                    :class="{
                                        'last-item':
                                            index === ds.quantities.length - 1,
                                    }"
                                >
                                    <div class="flex justify-start">
                                        <div
                                            class="cursor-pointer"
                                            @click="editLine(qt, ds)"
                                        >
                                            <Icon
                                                :type="
                                                    qt.edit ? 'check' : 'pencil'
                                                "
                                                class="w-5 h-5"
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </template>
                    <tr class="footer-total">
                        <td
                            class="w-2/5 px-3 py-4 text-sm uppercase border-none"
                        >
                            {{ $t("total_value") }}
                        </td>
                        <td></td>
                        <td>
                            <strong>
                                {{ valueTotalCurrent }}
                            </strong>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- Valor Total -->
        <div class="flex items-center rounded-md footer-total">
            <td class="w-3/5 px-2 py-4 font-bold uppercase">
                {{ $t("total_value") }}
            </td>
            <td class="px-2">
                <strong>{{ store.formatMoney(calculateGrandTotal) }}</strong>
            </td>
        </div>
    </div>
</template>
<script>
import Icon from "@/containers/UI/Icon.vue";
import { store } from "@/store";
import axios from "axios";

export default {
    components: {
        Icon,
    },
    props: {
        info: Array,
        expenses: Object,
    },
    data() {
        return {
            store,
            expensesRequested: [],
            currentExpenses: [],

            expandedInfo: null,
            openModal: true,
            categorySelected: [],
            totalValue: "",
            valueTotalCurrent: "",
        };
    },
    async created() {
        this.handleCurrentExpenses();
        this.handleExpensesRequested();

        this.updateTotalValue();
        this.updateTotalValueCurrent();
    },

    computed: {
        calculaTotal() {
            return this.expensesRequested.reduce(
                (total, ds) => total + parseFloat(ds.value),
                0
            );
        },
        calculaTotalCorrent() {
            return this.currentExpenses.reduce(
                (total, ds) => total + parseFloat(ds.value),
                0
            );
        },
        calculateGrandTotal() {
            const totalGeral = this.calculaTotal + this.calculaTotalCorrent;
            return totalGeral;
        },
    },
    methods: {
        isCapital(category) {
            return [
                "equipment_expenses",
                "construction_and_installation_expenses",
            ].includes(category);
        },
        handleCurrentExpenses() {
            const categoryToNameMap = {
                service_expenses: "description",
                prototype_expenses: "description",
                construction_and_installation_expenses: "description",
                costs_expenses: "description",
                importation_expenses: "description",
                supply_capacity_expenses: "description",
                equipment_expenses: "equipment",
                rights_and_properties_expenses: "type",
                scholarship_expenses: "type",
                taxes_expenses: "tax",
                allowance_expenses: "activity",
                tests_expenses: "activity",
                specific_expenses: "expense",
            };

            const filteredCategories = store.expensesCategories.filter(
                (category) => {
                    return !this.isCapital(category);
                }
            );

            let data = {};
            for (const category of filteredCategories) {
                for (const expense of this.expenses[category]) {
                    if (!(expense.category in data)) {
                        data[expense.category] = {
                            name: category,
                            value: 0,
                            amount: 0,
                            isActive: false,
                            quantities: [],
                        };
                    }
                    data[expense.category].value += expense.value;
                    data[expense.category].amount += 1;

                    // Utilize o mapeamento para obter o nome correto ou "material" como fallback
                    const nameField = categoryToNameMap[category] || "material";
                    const nameValue = expense[nameField] || "";

                    data[expense.category].quantities.push({
                        id: expense._id,
                        edit: false,
                        name: nameValue,
                        value: this.store.formatMoney(expense.value),
                    });
                }
            }
            this.currentExpenses = Object.values(data);
        },
        handleExpensesRequested() {
            const filteredCategories = store.expensesCategories.filter(
                (category) => {
                    return this.isCapital(category);
                }
            );
            let data = {};
            for (const category of filteredCategories) {
                for (const expense of this.expenses[category]) {
                    if (!(expense.category in data)) {
                        data[expense.category] = {
                            name: category,
                            value: 0,
                            amount: 0,
                            isActive: false,
                            quantities: [],
                        };
                    }

                    data[expense.category].value += expense.value;
                    data[expense.category].amount += 1;
                    data[expense.category].quantities.push({
                        id: expense._id,
                        edit: false,
                        name: expense.justification || "",
                        value: this.store.formatMoney(expense.value),
                    });
                }
            }

            this.expensesRequested = Object.values(data);
        },
        getRow(dsId) {
            for (const expense of this.currentExpenses) {
                if (expense.id === dsId.id) {
                    expense.isActive = !expense.isActive;
                } else {
                    expense.isActive = false;
                }
            }
        },
        getRowExpenses(dsId) {
            for (const expense of this.expensesRequested) {
                if (expense.id === dsId.id) {
                    expense.isActive = !expense.isActive;
                } else {
                    expense.isActive = false;
                }
            }
        },
        openRegisterPTR() {
            this.$router.push("/form");
        },
        formatCurrency(value) {
            if (!value) return "";
            value = value.replace(/\D/g, "");
            let numberValue = parseInt(value) / 100;
            return numberValue.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            });
        },
        onInput(index, event) {
            let formattedValue = this.store.formatCurrency(event.target.value);
            if (this.expensesRequested[0]) {
                this.expensesRequested[0].quantities[index].value =
                    formattedValue;
            }
            this.updateTotalValue();
        },
        updateTotalValue() {
            if (this.expensesRequested[0]) {
                let total = this.expensesRequested[0].quantities.reduce(
                    (sum, item) => {
                        return sum + this.store.parseCurrency(item.value);
                    },
                    0
                );

                this.totalValue = this.store.formatCurrency(total.toString());
            }
        },
        onInputCurrent(index, event) {
            let formattedValue = this.store.formatCurrency(event.target.value);
            if (this.currentExpenses[0]) {
                this.currentExpenses[0].quantities[index].value =
                    formattedValue;
            }
            this.updateTotalValueCurrent();
        },
        updateTotalValueCurrent() {
            let total = this.currentExpenses[0].quantities.reduce(
                (sum, item) => {
                    return sum + this.store.parseCurrency(item.value);
                },
                0
            );
            this.valueTotalCurrent = this.store.formatCurrency(
                total.toString()
            );
        },
        async editLine(qt, ds) {
            const authHeader = store.getAuthHeader();
            let body = {
                value: this.store.formatValueToSave(qt.value),
                name: qt.name,
            };

            if (qt.edit === true) {
                qt.edit = false;

                let res;
                try {
                    res = await axios.put(
                        `${store.BACKEND_URL}/api/projects/${this.$route.params.id}/budget/${qt.id}/?category=${ds.name}`,
                        body,
                        { withCredentials: false, headers: authHeader }
                    );
                    if (res.status == 204) {
                        this.$toast.success("Alterado com sucesso.");
                    } else {
                        this.$toast.error("Erro ao alterar.");
                    }
                } catch (err) {
                    console.log(err);
                }
            } else {
                qt.edit = true;
            }
        },
    },
};
</script>
<style>
.size-qtd {
    width: 20%;
}

.last-item {
    border-bottom: 1px solid rgb(17, 17, 17) !important;
}
</style>
