<template>
    <div>
        <div class="flex flex-row items-center justify-between">
            <div class="w-full">
                <div class="flex flex-row my-4">
                    <div
                        class="flex items-center justify-start w-1/3 p-0 px-4 sty-input"
                    >
                        <Icon type="search" class="mr-2" />
                        <input
                            type="text"
                            class="w-full py-3 bg-transparent outline-none placeholder:italic"
                            :placeholder="$t('search_report')"
                            v-model="searchProject"
                        />
                    </div>
                </div>
                <div class="flex flex-wrap items-center">
                    <div class="mr-2">
                        <span class="mr-4 txt-default"
                            >{{ $t("items_per_page") }}:</span
                        >
                        <select
                            v-model="itemsPerPage"
                            @change="setCurrentPage(1)"
                            class="w-20 py-2 mr-1 sty-input"
                        >
                            <option
                                v-for="option in itemsPerPageOptions"
                                :key="option"
                                :value="option"
                            >
                                {{ option }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="flex flex-row">
                <label for="report-id" class="px-1 btn-default"
                    >{{ $t("add_report") }}
                    <input
                        type="file"
                        id="report-id"
                        ref="reportRef"
                        class="hidden"
                        @change="saveReport"
                    />
                </label>
            </div>
        </div>
        <div class="my-2 overflow-y-auto" v-if="!uploading">
            <table class="min-w-full rounded-table">
                <thead class="bg-thead-tbl">
                    <tr class="rounded-lg bg-tr">
                        <th
                            class="px-4 py-2 cursor-pointer"
                            v-for="(column, index) in columns"
                            :key="index"
                            @click="sortBy(column)"
                        >
                            <div
                                class="flex items-center justify-center row-auto"
                            >
                                {{ $t(column.label) }}
                                <span v-if="column.sortable" class="ml-1">
                                    <Icon
                                        class="w-6 h-6 color-icon"
                                        :type="
                                            sortDirection === 'desc'
                                                ? 'arrow-up'
                                                : 'arrow-down'
                                        "
                                    />
                                </span>
                            </div>
                        </th>
                        <th></th>
                    </tr>
                </thead>

                <tbody class="b-tbody">
                    <template v-for="(item, id) in filterReport" :key="id">
                        <tr :class="item.isActive ? 'bg-thead-tbl' : ''">
                            <td
                                v-for="(column, index) in columns"
                                :key="item.id"
                                class="py-3 size-td"
                            >
                                <div
                                    v-if="column.field == 'situation'"
                                    class="flex items-center justify-center text-sm"
                                >
                                    <div
                                        class="flex items-center justify-center px-6 py-1 text-green-700 bg-green-200 border border-green-500 rounded-md"
                                    >
                                        {{ $t(item[column.field]) }}
                                    </div>
                                </div>
                                <div
                                    v-else
                                    class="flex items-center justify-center h-8 text-sm rounded-md"
                                >
                                    {{ item[column.field] }}
                                </div>
                            </td>
                            <td class="w-24 px-6 py-3">
                                <div
                                    class="flex items-center justify-center h-8"
                                >
                                    <div
                                        class="p-1 btn-default"
                                        @click="downloadReport(item.id)"
                                    >
                                        Download
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
            <div class="mt-4">
                <nav class="flex justify-between" v-if="items.length > 0">
                    <div>
                        <span class="mr-2">{{ $t("pages") }}:</span>
                        <button
                            v-for="pageNumber in totalPages"
                            :key="pageNumber"
                            @click="setCurrentPage(pageNumber)"
                            class="pagination"
                            :class="{
                                'selected-page': pageNumber === currentPage,
                            }"
                        >
                            {{ pageNumber }}
                        </button>
                    </div>
                </nav>
                <div class="my-2 text-gray-500" v-else>
                    {{ $t("no_data") }}.
                </div>
            </div>
        </div>
        <div class="w-full h-[30vh] flex items-center justify-center" v-else>
            <Spinner />
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { store } from "@/store";
import Icon from "@/containers/UI/Icon.vue";
import Spinner from "@/containers/UI/Spinner.vue";

export default {
    components: { Icon, Spinner },
    props: {
        projectId: String,
    },
    data() {
        return {
            uploading: false,
            store,
            columns: [
                { field: "period", sortable: true, label: "period" },
                { field: "name", sortable: true, label: "name" },
            ],
            items: [],
            sortField: null,
            sortDirection: "asc",

            itemsPerPageOptions: [5, 10, 30],
            itemsPerPage: 5,
            currentPage: 1,
            searchProject: "",
        };
    },
    created() {
        this.getDocReports();
    },
    computed: {
        sortedItems() {
            if (this.sortField) {
                const field = this.sortField;
                const direction = this.sortDirection === "desc" ? 1 : -1;

                return this.items.slice().sort((a, b) => {
                    if (a[field] < b[field]) return -direction;
                    if (a[field] > b[field]) return direction;
                    return 0;
                });
            }

            return this.items;
        },
        filterReport() {
            return this.paginatedItems.filter((proj) => {
                return proj.name
                    .toLowerCase()
                    .includes(this.searchProject?.toLowerCase());
            });
        },
        paginatedItems() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            return this.sortedItems.slice(startIndex, endIndex);
        },
        totalPages() {
            return Math.ceil(this.sortedItems.length / this.itemsPerPage);
        },
    },
    methods: {
        async getDocReports() {
            this.items = [];
            if (!this.projectId) {
                return;
            }
            const authHeader = store.getAuthHeader();

            const res = await axios.get(
                `${store.BACKEND_URL}/api/projects/${this.projectId}/reports/`,
                { withCredentials: false, headers: authHeader }
            );
            if (res.status != 200) {
                this.$toast.error(
                    "Something went wrong retrieving project information."
                );
                return;
            }

            for (const report of res.data) {
                const date = new Date(report.created_at);
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const dt = date.getDate();

                const monthStr = month < 10 ? "0" + month : "" + month;
                const dayStr = dt < 10 ? "0" + dt : "" + dt;
                const dateStr = `${year}-${monthStr}-${dayStr}`;

                this.items.push({
                    id: report.id,
                    period: dateStr,
                    name: report.filename,
                });
            }
        },
        async downloadReport(id) {
            this.uploading = true;
            const authHeader = store.getAuthHeader();
            const res = await axios.get(
                `${store.BACKEND_URL}/api/projects/${this.projectId}/reports/${id}/`,
                {
                    responseType: "arraybuffer",
                    withCredentials: false,
                    headers: authHeader,
                }
            );
            this.uploading = false;
            if (res.status != 200) {
                this.$toast.error("Something went wrong downloading file.");
                return;
            }

            const contentDisposition = res.headers["content-disposition"];
            const fileNameMatch =
                contentDisposition &&
                contentDisposition.match(/filename="(.+?)"/);

            const fileName = fileNameMatch
                ? fileNameMatch[1]
                : `ptr-${projectId}.xlsx`;

            const blob = new Blob([res.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        },
        async saveReport(event) {
            this.uploading = true;
            const authHeader = store.getAuthHeader();

            if (event.target.files.length > 1) {
                this.$toast.warning("Only one file can be uploaded at a time.");
                this.uploading = false;
                return;
            }

            const file = event.target.files[0];
            const formData = new FormData();

            formData.append("report", file);
            const res = await axios.post(
                `${store.BACKEND_URL}/api/projects/${this.projectId}/reports/`,
                formData,
                { withCredentials: false, headers: authHeader }
            );

            if (res.status == 201) {
                this.getDocReports();
            }
            this.uploading = false;
            if (res.status >= 400) {
                this.$toast.error("Something went wrong uploading file.");
                console.error(error);
                return;
            }
        },
        sortBy(column) {
            if (column.sortable) {
                this.sortDirection =
                    this.sortDirection === "asc" ? "desc" : "asc";
                this.sortField = column.field;
            }
        },
        setCurrentPage(pageNumber) {
            if (pageNumber >= 1 && pageNumber <= this.totalPages) {
                this.currentPage = pageNumber;
            }
        },
    },
};
</script>
