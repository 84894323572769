<template>
    <div ref="menuContainer">
        <div
            class="flex justify-between items-center cursor-pointer"
            @click="openMenu"
        >
            <div class="flex col-auto justify-center items-center">
                <div
                    class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-600 rounded-full"
                >
                    <img
                        src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
                        alt=""
                    />
                </div>
                <div class="flex flex-col mx-2">
                    <div class="font-bold">{{ profile.name }}</div>
                    <span class="text-sm">{{ profile.email }}</span>
                </div>
            </div>
            <div class="mx-3">
                <Icon
                    type="arrow-down"
                    class="w-4 h-4"
                    :class="dropMenu ? 'rotate-up' : 'rotate-down'"
                />
            </div>
        </div>
    </div>
    <div
        class="absolute w-48 right-6 mt-2 overflow-hidden transition-all duration-500 all-box"
        :style="dropMenuStyles"
        @click.stop
    >
        <div
            class="z-10 text-left origin-top-right rounded-md border border-gray-400 bg-gray-100"
        >
            <div
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
                class="divide-y divide-slate-300"
            >
                <div
                    class="flex flex-row text-md py-2 px-3 hover-option cursor-pointer"
                    @click="openlinguages = !openlinguages"
                >
                    <div>{{ $t("change_language") }}</div>
                </div>
                <div v-if="openlinguages" class="divide-y divide-slate-300">
                    <div
                        class="text-sm hover-option flex justify-between items-center option-flag"
                        v-for="lg in lang"
                        @click="switchLang(lg)"
                    >
                        <div class="flex flex-row py-1 w-full">
                            <img :src="lg.flag" width="20px" alt="" />
                            <div class="mx-2">{{ lg.language }}</div>
                        </div>
                        <Icon
                            type="check"
                            class="w-4 h-4 text-selected"
                            v-if="lg.selected"
                        />
                    </div>
                </div>
                <div
                    class="flex flex-row text-base py-2 px-3 hover:bg-red-400/50 cursor-pointer"
                    @click="goToOut"
                >
                    <div>{{ $t("logout") }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Icon from "@/containers/UI/Icon.vue";
import { store } from "@/store";
import axios from "axios";

export default {
    components: { Icon },
    data() {
        return {
            sizeWidth: window.innerWidth,
            dropMenu: false,
            openlinguages: false,
            profile: [],
            photoContent: "",
            store,
            lang: [
                {
                    language: "Português",
                    acronym: "pt",
                    flag: require("../assets/img/brazil-.png"),
                    selected: true,
                },
                {
                    language: "English",
                    acronym: "en",
                    flag: require("../assets/img/united-states.png"),
                    selected: false,
                },
            ],
        };
    },
    computed: {
        dropMenuStyles() {
            return {
                "max-height": this.dropMenu ? "1000px" : "0",
                opacity: this.dropMenu ? "1" : "0",
            };
        },
        initials() {
            if (this.profile.nome) {
                const words = this.profile.nome.split(" ");
                let initials = "";
                for (let i = 0; i < words.length && i < 2; i++) {
                    initials += words[i].charAt(0).toUpperCase();
                }
                return initials;
            }
            return "";
        },
    },
    created() {
        this.getUser();
        let lang = localStorage.getItem("i18nextLng");
        if (lang) {
            this.lang.forEach((lg) => {
                if (lg.acronym === lang) {
                    lg.selected = true;
                } else {
                    lg.selected = false;
                }
            });
        }
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
        document.addEventListener("click", this.closeAvatar);
        this.handleResize();
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
        document.removeEventListener("click", this.closeAvatar);
    },
    methods: {
        handleResize() {
            this.sizeWidth = window.innerWidth;
        },
        closeAvatar(event) {
            if (
                this.$refs.menuContainer &&
                !this.$refs.menuContainer.contains(event.target)
            ) {
                this.dropMenu = false;
                this.openlinguages = false;
            }
        },
        goToOut() {
            localStorage.clear();
            this.$router.push("/");
        },
        async getUser() {
            const authHeader = store.getAuthHeader();
            let res;
            try {
                res = await axios.get(`${store.BACKEND_URL}/api/users/me/`, {
                    withCredentials: false,
                    headers: authHeader,
                });
            } catch (er) {
                console.log(er);
                return;
            }
            this.profile = {
                cnpjCpf: res.data.login,
                email: res.data.email,
                name: res.data.name,
            };
        },

        openMenu(event) {
            event.stopPropagation();
            this.dropMenu = !this.dropMenu;
        },
        switchLang(lg) {
            this.lang.forEach((lg) => {
                lg.selected = false;
            });
            lg.selected = true;
            this.$i18next.changeLanguage(lg.acronym);
        },
    },
};
</script>
