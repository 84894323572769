<template>
    <div>
        <div class="box-default size-box-section">
            <div class="title-form-ptr">{{ $t("documents") }}</div>
            <div class="flex flex-row justify-between mb-2">
                <div class="label-ptr">{{ $t("list_documents") }}</div>
                <div
                    class="p-0 cursor-pointer btn-default text-md"
                    @click="openModal"
                >
                    {{ $t("file_upload") }}
                </div>
            </div>
            <div class="my-2 overflow-y-auto">
                <table class="min-w-full rounded-table">
                    <thead class="bg-thead-tbl">
                        <tr class="rounded-lg bg-tr">
                            <th class="p-2 text-start">{{ $t("order") }}</th>
                            <th class="p-2 text-start">{{ $t("name") }}</th>
                            <th class="p-2 text-start">
                                {{ $t("description") }}
                            </th>
                            <th class="text-start">{{ $t("upload_date") }}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody class="b-tbody">
                        <tr v-for="(item, index) in items" :key="item.order">
                            <td class="w-24">{{ index + 1 }}</td>
                            <td class="w-96">{{ item.filename }}</td>
                            <td class="w-96">{{ item.description }}</td>
                            <td class="w-32">{{ item.created_at }}</td>
                            <td class="w-32 text-center">
                                <div
                                    class="text-red-700 cursor-pointer"
                                    @click="removeItem(index)"
                                >
                                    <Icon type="trash" class="w-6 h-6" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="flex justify-between my-3">
            <div
                v-if="currentIndex > 0"
                @click="previousPage"
                class="my-1 btn-default"
            >
                {{ $t("previous") }}
            </div>
            <div
                @click="handleNextPage"
                class="my-1 btn-default btn-master"
                v-if="!loading"
            >
                {{ $t("next") + " | " + $t("save") }}
            </div>
            <div
                v-else
                class="my-1 btn-default btn-master inline-flex items-center relative"
            >
                {{ $t("saving") }}
                <div class="loading-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from "@/store";
import Icon from "@/containers/UI/Icon.vue";
import axios from "axios";

export default {
    props: {
        nextPage: Function,
        previousPage: Function,
        currentIndex: Number,
    },

    components: {
        Icon,
    },

    data() {
        return {
            store,
            items: [],
            loading: false,
        };
    },
    created() {
        this.emitter.on("sendDocumentPtr", (data) => {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, "0");
            const day = String(now.getDate()).padStart(2, "0");

            this.items.push({
                filename: data.document.name,
                description: data.description,
                address: "",
                flavor: "",
                created_at: `${year}-${month}-${day}`,
            });
        });

        let data = localStorage.getItem("ptrDatas")
            ? JSON.parse(localStorage.getItem("ptrDatas"))
            : [];

        if (data != null && data.hasOwnProperty("attachments")) {
            for (const at of data.attachments) {
                this.items.push({
                    filename: at.filename,
                    description: at.description,
                    created_at: at.created_at.split("T")[0],
                });
            }
        }
    },
    methods: {
        openModal() {
            this.emitter.emit("openModalfile", true);
        },
        removeItem(index) {
            this.items.splice(index, 1);
        },
        async handleNextPage() {
            this.loading = true;

            let parsedData = localStorage.getItem("ptrDatas")
                ? JSON.parse(localStorage.getItem("ptrDatas"))
                : {};

            parsedData.attachments = parsedData.attachments || [];
            parsedData.attachments.push(...this.items);

            localStorage.setItem("ptrDatas", JSON.stringify(parsedData));

            setTimeout(() => {
                this.nextPage();
                this.loading = false;
            }, 1500);
        },
    },
};
</script>
