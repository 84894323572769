<template>
    <div>
        <div class="grid py-3">
            <div class="w-full">
                <div class="my-2 overflow-y-auto" v-if="!this.uploading">
                    <table class="min-w-full rounded-table">
                        <thead class="bg-thead-tbl">
                            <tr>
                                <th
                                    class="px-4 py-2 cursor-pointer"
                                    v-for="(column, index) in columns"
                                    :key="index"
                                    @click="sortBy(column)"
                                >
                                    <div class="flex row-auto">
                                        {{ $t(column.label) }}
                                        <span
                                            v-if="column.sortable"
                                            class="ml-1"
                                        >
                                            <Icon
                                                class="w-6 h-6 color-icon"
                                                :type="
                                                    sortDirection === 'desc'
                                                        ? 'arrow-up'
                                                        : 'arrow-down'
                                                "
                                            />
                                        </span>
                                    </div>
                                </th>
                            </tr>
                        </thead>

                        <tbody class="b-tbody">
                            <template
                                v-for="(item, id) in paginatedItems"
                                :key="id"
                            >
                                <tr>
                                    <td
                                        class="px-4 py-2 w-[15%]"
                                        v-for="(column, index) in columns"
                                        :key="index"
                                    >
                                        <span
                                            class="txt-tbody"
                                            :title="item[column.field]"
                                            v-if="
                                                column.field == 'document_name'
                                            "
                                        >
                                            {{ item[column.field] }}
                                        </span>
                                        <span
                                            class="txt-tbody"
                                            :title="item[column.field]"
                                            v-else
                                            >{{ item[column.field] }}</span
                                        >
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    <div class="mt-4">
                        <nav
                            class="flex justify-between"
                            v-if="items.length > 0"
                        >
                            <div class="pagination-container">
                                <button
                                    @click="setCurrentPage(1)"
                                    :disabled="currentPage === 1"
                                >
                                    <Icon
                                        type="chevrons-left"
                                        class="w-6 h-6"
                                    />
                                </button>

                                <button
                                    @click="setCurrentPage(currentPage - 1)"
                                    :disabled="currentPage === 1"
                                >
                                    <Icon type="arrow-left" class="w-6 h-6" />
                                </button>

                                <button
                                    v-for="pageNumber in visiblePages"
                                    :key="pageNumber"
                                    @click="setCurrentPage(pageNumber)"
                                    class="pagination"
                                    :class="{
                                        'selected-page':
                                            pageNumber === currentPage,
                                    }"
                                >
                                    {{ pageNumber }}
                                </button>

                                <button
                                    @click="setCurrentPage(currentPage + 1)"
                                    :disabled="currentPage === totalPages"
                                >
                                    <Icon type="arrow-right" class="w-6 h-6" />
                                </button>

                                <button
                                    @click="setCurrentPage(totalPages)"
                                    :disabled="currentPage === totalPages"
                                >
                                    <Icon
                                        type="chevrons-right"
                                        class="w-6 h-6"
                                    />
                                </button>
                            </div>
                        </nav>
                        <div
                            class="my-2 text-gray-500"
                            v-if="items.length === 0"
                        >
                            {{ $t("no_data") }}
                        </div>
                    </div>
                </div>

                <div
                    class="w-full h-[25vh] flex items-center justify-center"
                    v-else
                >
                    <Spinner />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from "@/store";
import axios from "axios";
import Icon from "@/containers/UI/Icon.vue";
import Spinner from "@/containers/UI/Spinner.vue";

export default {
    props: {
        projectId: String,
    },
    components: {
        Icon,
        Spinner,
    },
    data() {
        return {
            uploading: false,
            items: [],
            columns: [
                {
                    field: "document_name",
                    sortable: true,
                    label: "document_name",
                },
                {
                    field: "reference",
                    sortable: true,
                    label: "reference",
                },
                { field: "upload_date", sortable: true, label: "upload_date" },
            ],
            itemsPerPageOptions: [5, 10, 15],
            itemsPerPage: 3,
            sortField: null,
            sortDirection: "asc",
            currentPage: 1,
        };
    },
    async mounted() {
        await this.loadDocuments();
    },
    created() {
        this.emitter.on("reload documents", (val) => {
            if (val) {
                this.loadDocuments();
            }
        });
    },
    computed: {
        visiblePages() {
            const range = 1;
            let startPage = Math.max(this.currentPage - range, 1);
            let endPage = Math.min(this.currentPage + range, this.totalPages);

            if (endPage - startPage < range * 2) {
                if (startPage === 1) {
                    endPage = Math.min(1 + range * 2, this.totalPages);
                } else if (endPage === this.totalPages) {
                    startPage = Math.max(this.totalPages - range * 2, 1);
                }
            }

            let pages = [];
            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }
            return pages;
        },

        sortedItems() {
            if (this.sortField) {
                const field = this.sortField;
                const direction = this.sortDirection === "desc" ? 1 : -1;

                return this.items.slice().sort((a, b) => {
                    if (a[field] < b[field]) return -direction;
                    if (a[field] > b[field]) return direction;
                    return 0;
                });
            }

            return this.items;
        },
        paginatedItems() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            return this.sortedItems.slice(startIndex, endIndex);
        },
        totalPages() {
            return Math.ceil(this.sortedItems.length / this.itemsPerPage);
        },
    },
    methods: {
        async loadDocuments() {
            this.items = [];
            const authHeader = store.getAuthHeader();

            const res = await axios.get(
                `${store.BACKEND_URL}/api/projects/${this.projectId}/documents/`,
                { withCredentials: false, headers: authHeader }
            );

            this.uploading = false;
            for (const doc of res.data) {
                const date = new Date(doc.created_at);
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const dt = date.getDate();

                const monthStr = month < 10 ? "0" + month : "" + month;
                const dayStr = dt < 10 ? "0" + dt : "" + dt;
                const dateStr = `${year}-${monthStr}-${dayStr}`;

                this.items.push({
                    id: doc._id,
                    document_name: doc.filename,
                    upload_date: dateStr,
                });
            }
        },
        async downloadDocument(id) {
            this.uploading = true;
            const authHeader = store.getAuthHeader();
            const res = await axios.get(
                `${store.BACKEND_URL}/api/projects/${this.projectId}/documents/${id}/`,
                {
                    responseType: "arraybuffer",
                    withCredentials: false,
                    headers: authHeader,
                }
            );
            this.uploading = false;
            if (res.status != 200) {
                this.$toast.error("Something went wrong downloading file.");
                return;
            }

            const contentDisposition = res.headers["content-disposition"];
            const fileNameMatch =
                contentDisposition &&
                contentDisposition.match(/filename="(.+?)"/);

            const fileName = fileNameMatch
                ? fileNameMatch[1]
                : `ptr-${projectId}.xlsx`;

            const blob = new Blob([res.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        },
        sortBy(column) {
            if (column.sortable) {
                this.sortDirection =
                    this.sortDirection === "asc" ? "desc" : "asc";
                this.sortField = column.field;
            }
        },
        setCurrentPage(pageNumber) {
            if (pageNumber >= 1 && pageNumber <= this.totalPages) {
                this.currentPage = pageNumber;
            }
        },
    },
};
</script>
