<template>
    <div>
        <div class="mb-8">
            <div class="flex flex-row justify-between">
                <div class="my-2 font-bold">
                    {{ $t("consolidated_budget") }}
                </div>

                <div class="flex flex-row">
                    <div class="btn-default" @click="openModalRequested">
                        {{ $t("request_change") }}
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-4">
            <div
                class="p-2 font-bold rounded-t-lg bg-thead-tbl border-separete"
            >
                {{ $t("capital_expenditure") }}
            </div>
            <table class="min-w-full">
                <thead class="border border-gray-300 bg-thead">
                    <tr>
                        <th
                            class="px-4 py-3 text-xs font-normal uppercase text-start"
                        >
                            {{ $t("expense_name") }}
                        </th>
                        <th
                            class="py-3 text-xs font-normal uppercase text-start"
                        >
                            {{ $t("current_value") }}
                        </th>
                        <th
                            class="py-3 text-xs font-normal uppercase text-start"
                        >
                            {{ $t("requested_amount") }}
                        </th>
                    </tr>
                </thead>
                <tbody class="border border-gray-300">
                    <tr v-for="(ds, dsID) in expensesRequested" :key="dsID">
                        <td colspan="1" class="px-3 w-[60%]">{{ ds.name }}</td>
                        <td class="w-[10%]">
                            {{ store.formatMoney(ds.value) }}
                        </td>
                        <td>{{ store.formatMoney(ds.value) }}</td>
                    </tr>
                    <tr class="footer-total">
                        <td class="px-3 py-4 text-sm uppercase">
                            {{ $t("total_value") }}
                            <span class="lowercase"
                                >({{ $t("capital_expenditure") }})</span
                            >
                        </td>
                        <td>
                            <strong
                                >{{ store.formatMoney(calculaTotal) }}
                            </strong>
                        </td>
                        <td>
                            <strong
                                >{{ store.formatMoney(calculaTotal) }}
                            </strong>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="mb-4">
            <div
                class="p-2 font-bold rounded-t-lg bg-thead-tbl border-separete"
            >
                {{ $t("current_expenses") }}
            </div>
            <table class="w-full">
                <thead class="border border-gray-300 bg-thead">
                    <tr>
                        <th
                            class="p-3 text-xs font-normal uppercase text-start"
                        >
                            {{ $t("expense_category") }}
                        </th>
                        <th
                            class="text-xs font-normal uppercase size-qtd text-start"
                        >
                            {{ $t("quantity_items") }}
                        </th>
                        <th
                            class="text-xs font-normal uppercase text-start w-[10%]"
                        >
                            {{ $t("current_value") }}
                        </th>
                        <th class="text-xs font-normal uppercase text-start">
                            {{ $t("requested_amount") }}
                        </th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody class="border border-gray-300">
                    <template v-for="(ds, dsId) in currentExpenses" :key="dsId">
                        <tr :class="ds.isActive ? 'bg-thead-tbl' : ''">
                            <td class="px-3">{{ ds.name }}</td>
                            <td>{{ ds.amount }}</td>
                            <td>{{ store.formatMoney(ds.value) }}</td>
                            <td>{{ store.formatMoney(ds.value) }}</td>
                            <td></td>
                            <td class="w-24 px-6">
                                <div
                                    class="flex items-center justify-center h-8 bg-white border border-gray-400 rounded-md cursor-pointer"
                                    @click="getRow(ds)"
                                >
                                    <Icon
                                        class="w-5 h-5 color-icon"
                                        type="arrow-down"
                                        :class="
                                            ds.isActive
                                                ? 'rotate-up'
                                                : 'rotate-down'
                                        "
                                    />
                                </div>
                            </td>
                        </tr>
                        <template
                            v-if="ds.isActive"
                            :colspan="currentExpenses.length"
                        >
                            <tr class="italic bg-gray-100">
                                <td class="px-3 text-xs uppercase border-none">
                                    Item
                                </td>
                                <td class="text-xs uppercase border-none">
                                    {{ $t("quantity") }}
                                </td>
                                <td class="text-xs uppercase border-none">
                                    {{ $t("current_value") }}
                                </td>
                                <td class="text-xs uppercase border-none">
                                    {{ $t("requested_amount") }}
                                </td>
                                <td class="text-xs uppercase border-none">
                                    {{ $t("justification") }}
                                </td>
                                <td class="text-xs uppercase border-none"></td>
                            </tr>
                            <tr
                                v-for="(qt, index) in ds.quantities"
                                :key="index"
                                class="bg-gray-100"
                            >
                                <td
                                    :class="{
                                        'last-item':
                                            index === ds.quantities.length - 1,
                                    }"
                                    class="px-3 border-none"
                                >
                                    {{ qt.name }}
                                </td>
                                <td
                                    :class="{
                                        'last-item':
                                            index === ds.quantities.length - 1,
                                    }"
                                    class="border-none"
                                >
                                    {{ qt.amount }}
                                </td>
                                <td
                                    :class="{
                                        'last-item':
                                            index === ds.quantities.length - 1,
                                    }"
                                    class="border-none"
                                >
                                    {{ store.formatMoney(qt.value) }}
                                </td>
                                <td
                                    :class="{
                                        'last-item':
                                            index === ds.quantities.length - 1,
                                    }"
                                    class="border-none"
                                >
                                    {{ store.formatMoney(qt.value) }}
                                </td>
                                <td
                                    :class="{
                                        'last-item':
                                            index === ds.quantities.length - 1,
                                    }"
                                    class="border-none"
                                >
                                    <div
                                        class="p-0 lowercase btn-default"
                                        @click="seeJustification()"
                                    >
                                        {{
                                            $t("see") +
                                            " " +
                                            $t("justification")
                                        }}
                                    </div>
                                </td>
                                <td
                                    :class="{
                                        'last-item':
                                            index === ds.quantities.length - 1,
                                    }"
                                    class="border-none"
                                ></td>
                            </tr>
                        </template>
                    </template>
                    <tr class="footer-total">
                        <td
                            class="w-2/5 px-3 py-4 text-sm uppercase border-none"
                        >
                            {{ $t("total_value") }}
                            <span class="lowercase"
                                >({{ $t("capital_expenditure") }})</span
                            >
                        </td>
                        <td></td>
                        <td>
                            <strong
                                >{{ store.formatMoney(calculaTotalCurrent) }}
                            </strong>
                        </td>
                        <td>
                            <strong
                                >{{ store.formatMoney(calculaTotalCurrent) }}
                            </strong>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- Valor Total -->
        <div class="flex items-center rounded-md footer-total">
            <td class="w-3/5 px-2 py-4 font-bold uppercase">
                {{ $t("total_value") }}
            </td>
            <td class="px-2">
                <strong>{{ store.formatMoney(calculateGrandTotal) }}</strong>
            </td>
        </div>
    </div>
</template>
<script>
import Icon from "@/containers/UI/Icon.vue";
import { store } from "@/store";

export default {
    components: {
        Icon,
    },
    props: {
        info: Array,
        expenses: Array,
    },
    data() {
        return {
            expensesRequested: [],

            currentExpenses: [],
            expandedInfo: null,
            openModal: true,
            categorySelected: [],
            store,
        };
    },
    created() {
        this.emitter.on("values expenses", (val) => {
            this.expensesRequested.push(val);
        });
        this.emitter.on("values current", (val) => {
            this.currentExpenses.push(val);
        });

        this.handleCurrentExpenses();
    },

    computed: {
        calculaTotal() {
            return this.expensesRequested.reduce(
                (total, ds) => total + parseFloat(ds.value),
                0
            );
        },
        calculaTotalCurrent() {
            return this.currentExpenses.reduce(
                (total, ds) => total + parseFloat(ds.value),
                0
            );
        },
        calculateGrandTotal() {
            const totalGeral = this.calculaTotal + this.calculaTotalCurrent;
            return totalGeral;
        },
    },
    methods: {
        handleCurrentExpenses() {
            let data = [];
            this.currentExpenses = data;
        },
        seeJustification() {},
        getRow(dsId) {
            for (const expense of this.currentExpenses) {
                if (expense.name === dsId.name) {
                    expense.isActive = !dsId.isActive;
                    if (expense.isActive) {
                        this.infoSelected = dsId;
                    }
                } else {
                    expense.isActive = false;
                }
            }
            this.categorySelected = dsId;
        },
        openModalRequested() {
            this.emitter.emit("openModalConsolidated", true);
        },
    },
};
</script>
<style>
.size-qtd {
    width: 20%;
}
</style>
