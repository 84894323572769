<template>
    <div>
        <div class="flex flex-row my-4">
            <div
                class="flex items-center justify-start w-1/3 p-0 px-4 sty-input"
            >
                <Icon type="search" class="mr-2" />
                <input
                    type="text"
                    class="w-full py-3 bg-transparent outline-none placeholder:italic"
                    :placeholder="$t('search_projects')"
                    v-model="searchProject"
                />
            </div>
        </div>
        <div class="flex flex-wrap items-center">
            <div class="mr-2">
                <span class="mr-4 txt-default"
                    >{{ $t("items_per_page") }}:</span
                >
                <select
                    v-model="itemsPerPage"
                    @change="setCurrentPage(1)"
                    class="w-20 py-2 mr-1 sty-input"
                >
                    <option
                        v-for="option in itemsPerPageOptions"
                        :key="option"
                        :value="option"
                    >
                        {{ option }}
                    </option>
                </select>
            </div>
        </div>

        <div class="my-2 overflow-y-auto">
            <table class="min-w-full rounded-table">
                <thead class="bg-thead-tbl">
                    <tr class="rounded-lg bg-tr">
                        <th
                            class="px-4 py-2 cursor-pointer"
                            v-for="(column, index) in columns"
                            :key="index"
                            @click="sortBy(column)"
                        >
                            <div
                                class="flex items-center justify-between row-auto"
                                v-if="column.label !== 'duration'"
                            >
                                {{ $t(column.label) }}
                                <span v-if="column.sortable" class="ml-1">
                                    <Icon
                                        class="w-6 h-6 color-icon"
                                        :type="
                                            sortDirection === 'desc'
                                                ? 'arrow-up'
                                                : 'arrow-down'
                                        "
                                    />
                                </span>
                            </div>
                            <div
                                class="flex items-center justify-between row-auto"
                                v-else
                            >
                                <div>
                                    {{ $t(column.label)
                                    }}<sup>{{ $t("in_months") }}</sup>
                                </div>
                                <span v-if="column.sortable" class="ml-1">
                                    <Icon
                                        class="w-6 h-6 color-icon"
                                        :type="
                                            sortDirection === 'desc'
                                                ? 'arrow-up'
                                                : 'arrow-down'
                                        "
                                    />
                                </span>
                            </div>
                        </th>
                        <th></th>
                    </tr>
                </thead>

                <tbody class="b-tbody">
                    <tr v-for="(item, id) in filterProjects" :key="id">
                        <td>{{ item.name }}</td>
                        <td
                            class="whitespace-nowrap overflow-ellipsis overflow-hidden max-w-80 pl-4 pr-8"
                            :title="item.description"
                        >
                            {{ item.description }}
                        </td>
                        <td class="px-4">
                            {{ item.start_month }}
                        </td>
                        <td class="px-4">
                            {{ item.final_month }}
                        </td>
                        <td class="px-4">
                            {{ item.duration + 1 }}
                        </td>
                        <td class="px-4" v-if="!item.isActive">
                            {{ parseFloat(item.progress.toFixed(2)) }}%
                        </td>
                        <td v-else>
                            <input
                                type="number"
                                min="0"
                                max="100"
                                class="p-1 bg-white sty-input text-center w-[50px]"
                                v-model="item.progress"
                            />
                        </td>
                        <td class="w-24 px-6 py-3">
                            <div
                                class="flex items-center justify-center py-1 cursor-pointer"
                                @click="getRow(item)"
                            >
                                <div v-if="!item.isActive">
                                    <Icon type="pencil" class="w-4 h-4" />
                                </div>
                                <div v-else @click="saveProgress(item)">
                                    <Icon type="check" class="w-4 h-4" />
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="mt-4">
                <nav class="flex justify-between" v-if="items.length > 0">
                    <div class="pagination-container">
                        <button
                            @click="setCurrentPage(1)"
                            :disabled="currentPage === 1"
                        >
                            <Icon type="chevrons-left" class="w-6 h-6" />
                        </button>

                        <button
                            @click="setCurrentPage(currentPage - 1)"
                            :disabled="currentPage === 1"
                        >
                            <Icon type="arrow-left" class="w-6 h-6" />
                        </button>

                        <button
                            v-for="pageNumber in visiblePages"
                            :key="pageNumber"
                            @click="setCurrentPage(pageNumber)"
                            class="pagination"
                            :class="{
                                'selected-page': pageNumber === currentPage,
                            }"
                        >
                            {{ pageNumber }}
                        </button>

                        <button
                            @click="setCurrentPage(currentPage + 1)"
                            :disabled="currentPage === totalPages"
                        >
                            <Icon type="arrow-right" class="w-6 h-6" />
                        </button>

                        <button
                            @click="setCurrentPage(totalPages)"
                            :disabled="currentPage === totalPages"
                        >
                            <Icon type="chevrons-right" class="w-6 h-6" />
                        </button>
                    </div>
                </nav>
                <div class="my-2 text-gray-500" v-if="items.length === 0">
                    {{ $t("no_data") }}.
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { store } from "@/store";
import Icon from "@/containers/UI/Icon.vue";

export default {
    props: {
        projectId: String,
        tbl: Object,
    },
    components: { Icon },
    data() {
        return {
            store,
            items: [],
            columns: [],
            sortField: null,
            sortDirection: "asc",

            itemsPerPageOptions: [10, 20, 30, 40, 50],
            itemsPerPage: 10,
            currentPage: 1,
            searchProject: "",
            infoSelected: {},

            temporaryValue: null,
        };
    },
    computed: {
        visiblePages() {
            const range = 1;
            let startPage = Math.max(this.currentPage - range, 1);
            let endPage = Math.min(this.currentPage + range, this.totalPages);

            if (endPage - startPage < range * 2) {
                if (startPage === 1) {
                    endPage = Math.min(1 + range * 2, this.totalPages);
                } else if (endPage === this.totalPages) {
                    startPage = Math.max(this.totalPages - range * 2, 1);
                }
            }

            let pages = [];
            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }
            return pages;
        },

        sortedItems() {
            if (this.sortField) {
                const field = this.sortField;
                const direction = this.sortDirection === "desc" ? 1 : -1;

                return this.items.slice().sort((a, b) => {
                    const aValue = a[field];
                    const bValue = b[field];

                    const isAtivFormat = (value) =>
                        typeof value === "string" &&
                        /^ATIV\.\s*\d+$/.test(value);

                    if (isAtivFormat(aValue) && isAtivFormat(bValue)) {
                        const getNumber = (str) => {
                            const match = str.match(/(\d+)/);
                            return match ? parseInt(match[0], 10) : 0;
                        };

                        const numA = getNumber(aValue);
                        const numB = getNumber(bValue);

                        return numA < numB
                            ? -direction
                            : numA > numB
                            ? direction
                            : 0;
                    } else if (
                        typeof aValue === "number" &&
                        typeof bValue === "number"
                    ) {
                        return aValue < bValue
                            ? -direction
                            : aValue > bValue
                            ? direction
                            : 0;
                    } else if (
                        typeof aValue === "string" &&
                        typeof bValue === "string"
                    ) {
                        return aValue.localeCompare(bValue) * direction;
                    } else if (
                        aValue instanceof Date &&
                        bValue instanceof Date
                    ) {
                        return aValue < bValue
                            ? -direction
                            : aValue > bValue
                            ? direction
                            : 0;
                    } else {
                        return (
                            aValue.toString().localeCompare(bValue.toString()) *
                            direction
                        );
                    }
                });
            }

            return this.items;
        },
        filterProjects() {
            return this.paginatedItems.filter((proj) => {
                return proj.name
                    .toLowerCase()
                    .includes(this.searchProject?.toLowerCase());
            });
        },
        paginatedItems() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            return this.sortedItems.slice(startIndex, endIndex);
        },
        totalPages() {
            return Math.ceil(this.sortedItems.length / this.itemsPerPage);
        },
    },

    created() {
        const items = this.tbl;
        let columns;
        if (!items.length) {
            columns = [];
        } else {
            columns = Object.keys(items[0]).map((key) => ({
                label: key,
                field: key,
                sortable: true,
            }));
        }

        const filteredColumns = columns.filter(
            (column) => !["id", "isActive"].includes(column.field)
        );

        this.columns = filteredColumns;
        this.items = items;
    },

    methods: {
        async saveProgress(item) {
            if (
                item.progress < 0 ||
                item.progress > 100 ||
                item.progress === ""
            ) {
                item.progress = this.temporaryValue;

                this.$toast.error(this.$t("progress_out_of_range"));
                return;
            }
            const authHeader = store.getAuthHeader();
            let res;
            try {
                res = await axios(
                    `${store.BACKEND_URL}/api/projects/${this.projectId}/activities/${item.id}/`,
                    {
                        method: "PUT",
                        headers: authHeader,
                        data: {
                            progress: item.progress,
                        },
                        withCredentials: false,
                    }
                );
            } catch (e) {
                if (!("response" in e) || e.response.status >= 400) {
                    this.$toast.error(this.$t("save_progress_error"));
                }
                return;
            }

            this.$toast.success(this.$t("save_progress_success"));
        },
        sortBy(column) {
            if (column.sortable) {
                this.sortDirection =
                    this.sortDirection === "asc" ? "desc" : "asc";
                this.sortField = column.field;
            }
        },
        setCurrentPage(pageNumber) {
            if (pageNumber >= 1 && pageNumber <= this.totalPages) {
                this.currentPage = pageNumber;
            }
        },
        getRow(item) {
            if (item.progress === null || item.progress === "") {
                this.temporaryValue = 0;
            } else {
                this.temporaryValue = item.progress;
            }

            for (const currentItem of this.items) {
                if (currentItem.name === item.name) {
                    currentItem.isActive = !currentItem.isActive;
                    if (currentItem.isActive) {
                        this.infoSelected = currentItem;
                    }
                } else {
                    currentItem.isActive = false;
                }
            }
        },
    },
};
</script>
<style>
.pagination-container {
    display: flex;
    gap: 8px;
}

.pagination {
    padding: 8px 12px;
}
</style>
